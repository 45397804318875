/* eslint-disable no-warning-comments */

import './src/styles/globals.scss'

import * as React from 'react'

import {PageTransition, PageTransitionWrapper, TRANSITION_EXIT} from '@/components/PageTransition'

export const wrapPageElement = ({element, props}) => (
  <>
    <PageTransition {...props} />
    <PageTransitionWrapper initial={false}>{element}</PageTransitionWrapper>
  </>
)

export const shouldUpdateScroll = ({routerProps: {location}, getSavedScrollPosition}) => {
  const duration = TRANSITION_EXIT * 1000

  if (location.hash !== '') {
    return true
  } else if (location.action === 'PUSH') {
    window.setTimeout(
      () =>
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'instant',
        }),
      duration
    )
  } else {
    const savedPosition = getSavedScrollPosition(location)
    window.setTimeout(() => window.scrollTo(...(savedPosition || [0, 0])), duration)
  }
  // if (window.__smoother) {
  //   // Anchor
  //   if (
  //     prevRouterProps &&
  //     location.pathname === prevRouterProps.location.pathname &&
  //     location.hash
  //   ) {
  //     window.__smoother.paused(false).scrollTo(location.hash, true, 'top top')
  //   }
  //   // Same URL
  //   else if (prevRouterProps && location.pathname === prevRouterProps.location.pathname) {
  //     window.__smoother.paused(false)
  //     window.__smoother.scrollTo(0, true)
  //   }
  //   // Link
  //   else if (location.action === 'PUSH') {
  //     window.setTimeout(() => window.__smoother.scrollTop(0), duration)
  //   }
  //   // Browser's forwards or back button
  //   else {
  //     const savedPosition = getSavedScrollPosition(location) || [0, 0]
  //     window.__smoother.paused(false)
  //     window.setTimeout(
  //       () => window.__smoother.scrollTo(savedPosition[1], true, 'top top'),
  //       duration //* 2
  //     )
  //   }
  // }

  return false
}
